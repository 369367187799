<template>
  <div class="mobile-nav">
    <div>
      <b-container fluid>
        <div v-if="!searchFlag"
          class="d-flex py-3 px-2 justify-content-between align-items-center"
        >
          <!-- Logo -->
          <router-link exact to="/" v-if="mode === 'dark'">
            <img class="logoNav" :src="require('@/assets/images/logo.png')" />
          </router-link>
          <router-link exact to="/" v-else>
            <img class="logoNav" :src="require('@/assets/images/logo_black.png')" />
          </router-link>

          <span class="d-flex align-items-center gap_1">
          <i class="las la-search cursor-pointer font-size-24" @click="searchFlag=true"></i>

          <!-- burger button -->
          <button
            class="bg-transparent text-warning border-0"
            @click="sideNav = true"
          >
            <i class="las la-bars la-2x"></i>
          </button>
          </span>

          <!-- Side Nav -->
          <side-nav :listItems="listItems" :sideNav="sideNav" @close="sideNav = false" @modeChange="changeLogo" />
        </div>

        <div v-if="searchFlag" class="d-flex py-3 px-2 justify-content-between align-items-center">
<!--          <transition
              name="fade"
              enter-active-class="animate__animated animate__slideInLeft animate__faster"
              leave-active-class="animate__animated animate__slideOutLeft animate__faster"
          >-->
          <transition
              name="scale"
          >
            <b-form-input @focus="hideIndicator(true)" @blur="searchFlag=false; hideIndicator(false)"
                          :placeholder="$t('search.search')" v-focus
                          @keyup.enter="$event.target.blur();
                          $router.push({name: 'search', query: { q: $event.target.value }})">
            </b-form-input>
          </transition>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import SideNav from './SideNav.vue'
import EventBus from '@/eventBus'
export default {
  props: ['listItems', 'mode'],
  components: { SideNav },
  data () {
    return {
      sideNav: false,
      searchFlag: false
      // mode: localStorage.getItem('dark')
    }
  },
  methods: {
    changeLogo (newMode) {
      console.log(newMode)
      this.mode = newMode
    },
    hideIndicator (flag) {
      EventBus.$emit('hide-indicator', flag)
    }
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.focus()
      }
    }
  }
}
</script>

<style>
@media only screen and (max-width: 500px) {
  .logoNav {
    width: 100px !important;
  }
}
</style>
