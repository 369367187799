<template>
  <!-- Footer -->
  <footer class="bg-white iq-footer pb-0">
    <!--Footer Links-->
    <b-container>
      <b-row class="pt-4">
        <b-col lg="3" class="mb-3">
          <img :src="goldLogo" alt="Curva" class="footer-logo" />
          <p class="text-white mt-4 font-size-12 text-justify">
            {{ $t('footer.slogan') }}
          </p>
        </b-col>
        <b-col lg="2" class="mx-auto text-initial mb-3">
          <h5 class="text-uppercase font-weight-bold mb-4">{{ $t('contact.info') }}</h5>
          <ul class="list-unstyled p-0">
            <li><i class="px-1 las la-map-marker-alt"></i> {{ $t('footer.location') }}</li>
            <li><i class="px-1 las la-phone-volume"></i>
              <span style="direction: ltr; display: inline-block;"> +20 100 723 9097</span></li>
            <li><i class="px-1 las la-envelope"></i> curvaegypt@gmail.com</li>
          </ul>
        </b-col>
        <b-col lg="4" class="mb-3">
          <b-row>
            <b-col lg="4" class="mx-auto text-initial">
              <ul class="list-unstyled mb-0 p-0">
                <b-row v-if="!ifLogin">
                  <b-col lg="12" cols="6" >
                  <li>
                    <router-link :to="{name: 'login'}">{{ $t('login.login') }}</router-link></li>
                  </b-col>
                  <b-col lg="12" cols="6" >
                    <li><router-link :to="{name: 'registration'}">{{ $t('mainNav.register') }}</router-link></li>
                  </b-col>
                </b-row>
                <li v-else><router-link to="/">{{ $t('mainNav.home') }}</router-link></li>
                <b-row>
                <b-col lg="12" cols="6" ><li>
                  <router-link :to="{name: 'about'}">{{ $t('mainNav.about') }}</router-link></li></b-col>
                <b-col lg="12" cols="6" ><li>
                  <router-link :to="{name: 'contact-us'}">{{ $t('mainNav.contactUs') }}</router-link></li></b-col>
                </b-row>
              </ul>
            </b-col>
            <b-col lg="4" cols="6" class="mx-auto text-initial">
              <ul class="list-unstyled p-0">
                <li><router-link to="categories/all-categories">{{ $t('footer.products') }}</router-link></li>
                <li><router-link :to="{name: 'offers'}">{{ $t('mainNav.offers') }}</router-link></li>
                <li><router-link :to="{name: 'blogs'}">{{ $t('mainNav.blogs') }}</router-link></li>
                <li><router-link :to="{name: 'social'}">{{ $t('mainNav.social') }}</router-link></li>
              </ul>
            </b-col>
            <b-col lg="4" cols="6" class="mx-auto text-initial">
              <ul class="list-unstyled p-0">
<!--                <li><a>{{ $t('mainNav.images') }}</a></li>
                <li><a>{{ $t('mainNav.videos') }}</a></li>-->
                <li><router-link :to="{name: 'hiring'}">{{ $t('mainNav.hiring') }}</router-link></li>
                <li><router-link :to="{name: 'complaints'}">{{ $t('mainNav.complaints') }}</router-link></li>
              </ul>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="3" class="mx-auto text-initial">
          <h5 class="text-uppercase font-weight-bold mb-4">
            {{ $t('footer.download') }}
          </h5>
          <div class="imgs d-flex justify-content-between">
            <div class="px-1">
              <img :src="iosDark" alt="Ios" class="w-100" />
            </div>
            <div class="px-1">
              <img :src="androidDark" alt="android" class="w-100" />
            </div>
          </div>
          <h5 class="text-uppercase font-weight-bold my-3">{{ $t('footer.follow') }}</h5>
          <ul class="d-flex p-0 font-size-22 footer-socials" style="gap: 20px">
            <li>
              <a href="https://www.instagram.com/curvaegy/" target="_blank"><i class="lab la-instagram"></i></a>
            </li>
            <li>
              <a href="https://www.facebook.com/CurvaEg1" target="_blank"><i class="lab la-facebook-f"></i></a>
            </li>
            <li>
              <a href="https://wa.me/201007239097" target="_blank"><i class="lab la-whatsapp"></i></a>
            </li>
            <li>
              <a href="https://twitter.com/CurvaEgypt" target="_blank"><i class="lab la-twitter"></i></a>
            </li>
            <li>
              <a href="https://www.tiktok.com/@curvaegy" target="_blank"><i class="fab fa-tiktok font-size-18"></i></a>
            </li>
            <li>
              <a href="#" target="_blank"><i class="lab la-linkedin"></i></a>
            </li>
          </ul>
        </b-col>
        <!--/.Fourth column-->
      </b-row>
    </b-container>

    <!--Copyright-->
    <b-row class="iq-style1 py-2">
      <b-col class="m-0 p-0">
        <p class="text-center m-0 p-0">{{ $t('footer.rights') }}</p>
      </b-col>
    </b-row>
    <!--/.Copyright-->
  </footer>
  <!--  <footer class="bg-white iq-footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12 text-center">
          CURVA © 2022. All Rights Reserved.
        </div>
      </div>
    </div>
  </footer>-->
  <!-- Footer END -->
</template>
<script>
export default {
  name: 'MiniFooter',
  data () {
    return {
      goldLogo: require('@/assets/images/curva-gold-logo.png'),
      iosDark: require('@/assets/images/curva/dark/Group 20.png'),
      androidDark: require('@/assets/images/curva/dark/Group 21.png')
    }
  },
  computed: {
    ifLogin () {
      return localStorage.getItem('userToken') ? localStorage.getItem('userToken') : this.$store.getters.getLoginStatus
    }
  }
}
</script>
